import food from '../images/food.jpg';
import viju from '../images/viju.png';
import laca from '../images/laca.png';
import fish from '../images/fish.jpg';
import beef from '../images/beef.jpg';
import chicken from '../images/chicken.jpg';
import fries from '../images/fries.jpg';
import chShawa from '../images/chShawa.jpg';
import hot from '../images/hot.jpg';
import burgers from '../images/burgers.jpg';
import soda from '../images/soda.jpeg';
import water from '../images/water.jpg';
import ssuya from '../images/ssuya.jpg';
import schickenwings from '../images/schickenwings.jpg';
import meatpie from '../images/meatpie.png';
import puff from '../images/puff.jpg';
import asun from '../images/asun.jpg';
import beefshawa from '../images/beefshawa.jpg';
import shrimp from '../images/shrimp.jpg';
import shrimpstick from '../images/shrimpstick.jfif';




const data = {
    menu:[
        {section:"Favorite item", subText:"The most commonly ordered items and dishes from us", 
            items:[
                {id:'1',name:"Chicken Suya", desc:`Marinated Chicken grilled to perfection with a juicy and tasty flavor. Contains
                cayenne pepper, bouillon powder, garlic, ginger, onions, smoked paprika, and peanut`, 
                price:10.00, img:chicken},
                {id:'2',name:"Beef Suya", desc:`Marinated beef grilled to perfection with a juicy and tasty flavor. Contains cayenne
                pepper, bouillon powder, garlic, ginger, onions, smoked paprika, and peanut.`,
                    price:10.00, img:beef},
                {id:'3',name:"Grilled Fish ", desc:`Fresh whole fish with a choice of tilapia, red snapper, mackerel fish, or salmon fillet.
                Salted and seasoned then grilled for a smokey and juicy taste`,
                    price:10.00, img:fish, options:[{name:'Tilapia,', price:0.00}, {name:'Red snapper,', price:0.00}, {name:'Mackerel fish,', price:0.00, }, {name:'Salmon fillet', price:0.00}]},
                {id:'4',name:"Asun:", desc:`Spicy smoked goat meat grilled and then sauteed with onions peppers and other spices.`, 
                price:15.00, img:asun},
            ]
            
        },

        {section:"All Food Items", subText:"", 
            items:[
                {id:'5',name:"Asun:", desc:`Spicy smoked goat meat grilled and then sauteed with onions peppers and other spices.`, 
                price:15.00, img:asun},

                {id:'6',name:"Chicken Shawarma", desc:`Our flagship Chicken Suya is sliced into a white flour tortilla wrap and mixed
                with purple and white cabbage with carrots and Bambi’s kitchen secret sauce. It also contains beef
                sausages/hot dogs`, 
                price:12.50, img:null},
                
                {id:'7',name:"Chicken Shawarma + Fries", desc:`Our flagship Chicken Suya is sliced into a white flour tortilla wrap and mixed
                with purple and white cabbage with carrots and Bambi’s kitchen secret sauce. It also contains beef
                sausages/hot dogs`, 
                price:15.00, img:chShawa},

                {id:'8',name:"Beef Shawarma", desc:`Our flagship Beef Suya is sliced into a white flour tortilla wrap and mixed with
                purple and white cabbage with carrots and Bambi’s kitchen secret sauce. It also contains beef
                sausages/hot dogs`,
                    price:12.50, img:beefshawa},


                {id:'9',name:"Beef Shawarma + Fries", desc:`Our flagship Beef Suya is sliced into a white flour tortilla wrap and mixed with
                purple and white cabbage with carrots and Bambi’s kitchen secret sauce. It also contains beef
                sausages/hot dogs`,
                    price:15.00, img:chShawa},

                {id:'10',name:"Shrimp Shawarma", desc:`Our flagship Shrimp Suya is sliced into a white flour tortilla wrap and mixed with
                purple and white cabbage with carrots and Bambi’s kitchen secret sauce. It also contains beef
                sausages/hot dogs`,
                    price:12.50, img:shrimp},

                {id:'11',name:"Shrimp Shawarma + Fries", desc:`Our flagship Shrimp Suya is sliced into a white flour tortilla wrap and mixed with
                purple and white cabbage with carrots and Bambi’s kitchen secret sauce. It also contains beef
                sausages/hot dogs`,
                    price:15.00, img:chShawa},

                {id:'12',name:"Chicken Suya", desc:`Marinated Chicken grilled to perfection with a juicy and tasty flavor. Contains
                cayenne pepper, bouillon powder, garlic, ginger, onions, smoked paprika, and peanut`, 
                price:10.00, img:chicken},

                {id:'13',name:"Beef Suya", desc:`Marinated beef grilled to perfection with a juicy and tasty flavor. Contains cayenne
                pepper, bouillon powder, garlic, ginger, onions, smoked paprika, and peanut.`,
                    price:10.00, img:beef},

                {id:'14',name:"Shrimp Suya", desc:`Marinated Shrimp grilled to perfection with a juicy and tasty flavor. Contains cayenne
                pepper, bouillon powder, garlic, ginger, onions, smoked paprika, and peanut`,
                    price:10.00, img:ssuya},

                {id:'15',name:"Grilled Fish ", desc:`Fresh whole fish with a choice of tilapia, red snapper, mackerel fish, or salmon fillet.
                Salted and seasoned then grilled for a smokey and juicy taste`, options:[{name:'Tilapia,', price:0.00}, {name:'Red snapper,', price:0.00}, {name:'Mackerel fish,', price:0.00, }, {name:'Salmon fillet', price:0.00}],
                    price:15.00, img:fish},

                {id:'16',name:"Shrimp on a stick", desc:`Jumbo shrimp seasoned with the option of jerk, garlic peri-peri, or Cajun seasoning.`,
                price:10.00, img:shrimpstick, options:[{name:'Jerk,', price:0.00}, {name:'Garlic peri-peri,', price:0.00}, {name:'Cajun', price:0.00}]},

                {id:'17',name:"Pepper indomie", desc:`Spice Noodles with fresh peppers, Carrots, Onions, and fried eggs.`,
                price:6.00, img:null},

                {id:'18',name:"Pepper indomie + Suya", desc:`Spice Noodles with fresh peppers, Carrots, Onions, and fried eggs with a
                choice to Beef, Chicken, or Shrimp Suya`,
                price:15.00, img:null, options:[{name:'Chicken', price:0.00}, {name:'Beef', price:0.00}, {name:'Shrimp', price:0.00}]},

                // {id:'13',name:"Hot dogs", desc:" Nigerian style grilled fish recipe. It is Tilapia fish grilled with a rich and spicy peppe sauce.",
                // price:5.00, img:hot},

                // {id:'14',name:"Burgers", desc:" Nigerian style grilled fish recipe. It is Tilapia fish grilled with a rich and spicy peppe sauce.",
                // price:6.00, img:burgers},
            ]
            
        },

        {section:"Sides and Appetizers ", subText:" Enjoy our delicious Nigerian style sides and appetizers", 
            items:[
                {id:'20',name:"Smoked chicken wings", desc:"Our signature smoked chicken wings are made with fresh chicken breast, seasoned with a special sauce. 10 pieces.",
                    price:8.00, img:schickenwings},
                    
                {id:'21',name:"Meat Pie", desc:`A tasty Nigerian patty that contains 90% lean ground beef, potatoes, carrots, and peas.
                Enveloped in a pastry-like dough and baked till slightly golden brown. 2 pieces.`,
                price:5.00, img:meatpie},

                {id:'22',name:"Puff Puff", desc:`A beloved Nigerian staple. A pastry dough that contains a mix of flour, yeast, hint of
                cinnamon, nutmeg and sugar fried until golden brown. 3 pieces.`,
                    price:2.00, img:puff},
                    
                {id:'23',name:"Samosa", desc:``,
                    price:5.00, img:null},

                {id:'24',name:"Pepper soup", desc:`A hot and spicy chicken broth recipe made with a blend of aromatic African spices. `,
                price:10.00, img:null},

                {id:'25',name:"Fried Yam", desc:`Crunchy on the outside and soft on the inside. Yam slices marinated in water and salt
                fried in vegetable oil. Includes a delicious sauce on the slide (A mixture of blended tomatoes, garlic,
                onions, peppers with other spices fried in vegetable oil).`,
                price:10.00, img:null},

                {id:'26',name:"Fried Yam + Mackerel Sauce:", desc:`Crunchy on the outside and soft on the inside. Yam slices marinated in
                water and salt fried in vegetable oil. Includes a delicious sauce on the slide (A mixture of blended
                tomatoes, garlic, onions, peppers with other spices fried in vegetable oil). Sauce choice of grilled
                mackerel.`,
                price:12.00, img:null},

                {id:'27',name:"French Fries", desc:`Though tastes and preferences differ, the perfect French fry generally has a crunchy exterior and a light, fluffy interior`,
                price:3.50, img:fries},
            ]
            
        },

        {section:"Drinks", subText:"", 
            items:[
                // {id:'5',name:"LaCasera", desc:"La Casera is the pioneer apple drink in Nigeria. It has an original taste, and a unique, fresh apple bite.",
                //     price:5.00, img:laca},
                    
                // {id:'6',name:"Viju Milk", desc:"Enjoy a well-flavored, nourishing, and refreshing milk drink in viju milk. Best when served chilled.",
                // price:5.00, img:viju},
                {id:'28',name:"Soda", desc:"Enjoy any soda in our selection of soft drinks. Best when served chilled.",
                    price:2.00, img:soda},
                    
                {id:'29',name:"Water", desc:"Chilled water is a refreshing drink that is great for your meal.",
                price:1.00, img:water},
            ]
            
        }
        
    ]

}


export default data;
import React, { useEffect, useState } from "react";
import ScrollToTop from "./provider/scrollToTop";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import Order from "./page/order";
import Catering from "./page/catering";
import Checkout from "./page/checkout";
import Home from "./page/home";
import { Rings } from "react-loader-spinner";
import { Center } from "./components/input";

function App() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const run = async () => {
    setIsLoading(true);
    try {
      const i = await fetch(
        "https://script.google.com/macros/s/AKfycbwGrtKiPC6uYy4bb7q59HlaizgK2sN7MKr5w0f4PWtDhIL03_cjwqsJkv0TTtB8j9sL7g/exec",
        {
          method: "GET",
        }
      );

      const result = await i.json();

      if (!result || result[0]?.Type === "") {
        throw Error("An error occured");
      }

      setData(result);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(err);
    }
  };

  useEffect(() => {
    run();
  }, []);

  if (error){

  }

  return (
    <>
      {!isLoading && data? (
        <Router>
          <ScrollToTop>
            {/* <Header/> */}
            <Switch>
              <Route exact path="/">
                <Home pageData={data}/>
              </Route>

              <Route exact path="/catering">
                <Catering pageData={data}/>
              </Route>

              <Route exact path="/order">
                <Order pageData={data}/>
              </Route>

              <Route exact path="/checkout">
                <Checkout pageData={data}/>
              </Route>
            </Switch>
            {/* <Footer/> */}
          </ScrollToTop>
        </Router>
      ) : (
        <Center>
          <Rings
            heigth="250px"
            width="250px"
            color="#FFD9CB"
            ariaLabel="loading"
          />
        </Center>
      )}
    </>
  );
}

export default App;

import React,{useState, useEffect} from "react";
import styled from "styled-components";
import food from '../images/food.jpg';
import notics from '../images/notics.svg';
import { Text, SubmitButton, TextInput } from "../components/input";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Checkmark } from 'react-checkmark'
import data from "../provider/pagedata";
import logo from "../images/logo.png";
import {CardElement, useElements, useStripe, Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { Rings } from  'react-loader-spinner'

const stripePromise = loadStripe('pk_live_51KHEbfBmJbNWvKKfUOKrnkJ3CBrOUXoprboIWdvuSiyKNRI7n1SQZaY1afEbqLwIYqDpQPAwWfVMmWw9J50gS7nd00Ku7BVWDh');


class Node{
    constructor(value, next = null){
        this.value = value;
        this.next = next;
    }
}


class Orderlist{
    constructor(){
        this.head = null;
        
    }

    add(value){
        const appendNode=(node)=>{
            if(this.head === null){
                this.head = new Node(value);
                return;
            }else if(node.next === null){
                node.next = new Node(value);
            }else{
                appendNode(node.next);
            }
        }

       return appendNode(this.head)
    }

}





const Completed =({orderDetails, orderId})=>{
    const history = useHistory();
    const num =[1,2,3,4,1,2,3,4,1,1,1,2,2,4,5, 1,2,3,4,1,2,3,4,1,1,1,2,2,4,5,1,1,2,3,4,1,2,3,4,1,1,1,2,2,4,5,2,3,4,1,2,3,4,1,1,1,2,2,4,5]

    useEffect(()=>{

        //detect if user leaves page
        window.addEventListener('beforeunload', (e) => {
            e.preventDefault();
            clearCart();
        });

        //detect if route changes from current page
        const unlisten = history.listen(()=>{
            clearCart();
        })
 
        return unlisten;
    }, [])
    

    const clearCart = () => {
        localStorage.setItem('cart', JSON.stringify([]));
    }

    return(
        <Main>
            <div style={{padding:'20px'}}>
                <Pad>
                    <Checkmark size='70px' color="#B82F00"/>

                    <Text weight='600' pad='30px 20px' size='20px'>Order  placed</Text>


                    <Text color="#8B8B8B" weight='400' pad='0px 20px 20px 20px' size='13px'>
                        Your order has been placed successfully. Your order id is: <span style={{color:'black', padding:'0px 7px', fontSize:'13px'}}>{orderId}</span>
                        You can contact us at <a style={{textDecoration:'none', fontSize:'13px', color:'black',}} href="tel:+16176971721">(617)-697-1721</a> anytime for any question about your order.
                    </Text>

                    <Ticket>
                        <div style={{padding:'0px 20px 40px 20px', textAlign:'left'}}>
                            <Text size="12px">
                                {orderDetails}
                            </Text>
                        </div>

                        <Bar>
                            <div style={{height:'20px',width:'20px',borderRadius:'20px',backgroundColor:'#fff', position:'absolute',left:'-30px', top:'-30px'}}/>
                            <div style={{height:'20px',width:'20px',borderRadius:'20px',backgroundColor:'#fff', position:'absolute',right:'-30px', top:'-30px'}}/>
                            {num.map((item, index)=>{ 
                               return <div key={index} style={{backgroundColor:'#393939',height:'100%', width:`${item}px`, margin:'0px 1px'}}/>})}
                        </Bar>
                    </Ticket>
                    <div style={{maxWidth:"280px", height:'55px', margin:'30px auto'}}>
                        <SubmitButton color='#B82F00' textColor='#fff' onClick={()=>history.push('/order')}>Back to menu</SubmitButton>
                    </div>
                </Pad>
            </div>
        </Main>
    )
}

const Main = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: #EEEEEE;

`

const Pad = styled.div`
    max-width: 600px;
    padding:20px;
    margin: 50px auto 0px auto;
    background-color: #FFFFFF;
    border-radius: 10px;
    text-align: center;
    @media (max-width: 500px) {
        margin: 20px auto 0px auto;
    }

`
const Ticket = styled.div`
    background-color:#F3F3F3;
    border-radius:10px;
    padding:30px 0px;
    margin:0px auto;
    width:270px;
`

const Bar = styled.div`
    display:flex;
    width:230px;
    height:60px;
    margin:0 auto;
    position:relative;
`


export default function Checkout(props) {
    const [isActive, setIsActive] = useState(false);
    const [details, setDetails] = useState({});

    return <Elements stripe={stripePromise} >
       {!isActive? <CheckoutElement completeDetails={setDetails} setIsActive={setIsActive} {...props}/>: 
       <Completed orderDetails={details.details} orderId={details.orderid}/>}
    </Elements>
}


function date(){
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    const dateObj = new Date();
    const month = monthNames[dateObj.getMonth()];
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    const output = month  + '\n'+ day  + ',' + year;

    return output
}


function CheckoutElement({completeDetails,setIsActive}) {
    const stripe = useStripe();
    const elements = useElements();
    const [type, setType] = useState('delivery');
    const [cartItems, setCartItems] = useState(JSON.parse(localStorage.getItem('cart')) || []);
    const [total, setTotal] = useState(0);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const [email, setEmail] = useState('');
    const [apt, setApt] = useState('');
    const [error, setError] = useState({type:null, message:null})
    const [stte, setStte] = useState('');
    const [cardError, setCardError] = useState(null);
    const [details, setDetails] = useState('');
    const [charge, setCharge] = useState(0);
    const [loading, setLoading] = useState(false);



    useEffect(() => {
        if(JSON.parse(localStorage.getItem('orderlist')) === null){
            localStorage.setItem('orderlist', JSON.stringify([]));
        }
        
        let s = setInterval(() => {
            handleTotal(0);
        }, 100);
        return () => clearInterval(s);
      }, [total]);


    // localStorage
    useEffect(() => {
        //find card id in data and concat to details;
        let detail = '';
        cartItems.map(food => {
            data.menu.forEach(item => {
                item.items.filter(item => item.id === food.id).map(item => {
                    let opt;
                    food?.options && (opt = item.options[food.options])
                    detail = "\n" + `Name: ` + item.name +'.     '+ "\n" + "Price: $" + item.price +'.     '+ "\n" + "Quantity: " + food.quantity + `${food?.options ? '\n' + 'Option: ' + opt.name : ''}` + '.     '+ "\n" + "Total: $" + item.price * food.quantity +'.     '+ "\n" + "Instructions: " + food.instructions +'.     '+ "\n" +"------------------------------------------------------------- \n" + "\n" + detail + "\n";      
                })
            })
        })
        setDetails(detail);


        //listen for changes in localStorage
        window.addEventListener('storage', handleStorageChange);
            return () => {
            window.removeEventListener('storage', handleStorageChange);
        }
    }, [cartItems]);

    // localStorage
    function handleStorageChange(event) {
        if (event.key === 'cart') {
            setCartItems(JSON.parse(event.newValue));
            handleTotal(0);
        }
    }

    // handle total price
    const handleTotal = (rr) => {
        setTotal(rr);
        if(cartItems.length > 0){
            cartItems.map(food => {
                data.menu.forEach(item => {
                    item.items.filter(item => item.id === food.id).map(item => {
                       return setTotal(prev=>prev+=(item.price * food.quantity));
                    })
                })
            })

            setCharge(type === 'delivery'?((total*0.0625)+ 5.00 + total):((total*0.0625) + total))
        }
    }


    

    // handle handle card
    const handleCard = async (e) => {
        e.preventDefault();
        setError({type:null, message:null});
        setCardError(null);
        setLoading(true);
        

        //check if total amount is greater than or equal to 15
        if(total < 15){
            setError({type:'payment', message:'Please order at least $15'});
            setLoading(false);
            return;
        }

        var fullAddress = `${address}${apt? ' '+apt+', ':', '}${city}, ${stte}, ${zip}`;
        let re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var orderid = Math.floor(Math.random() * 1000000000000)

        if(name===''|| name === null){  setError({type:'name', message:'Full name is required'}); return setLoading(false);}
        if (!re.test(email)){ setError({type:'email', message:'Please enter a valid email'}); return setLoading(false);}
        if (phone === '' || phone === null || phone.length < 10){ setError({type:'phone', message:'Please enter a valid phone number'}); return setLoading(false);}
        if (address === '' || address === null ) { setError({type:'address', message:'Please enter a valid address'}); return setLoading(false);}
        if (city === '' || city === null ){ setError({type:'city', message:'Enter valid city'}); return setLoading(false);}
        if (stte === '' || stte === null){ setError({type:'state', message:'Enter state'}); return setLoading(false);}
        if (zip === '' || zip === null|| zip.length < 5){ setError({type:'zip', message:'Please enter a valid Zipcode'}); return setLoading(false);}


        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        if (error) {
            setCardError(error.message);
            setLoading(false);
        }
        else {
            fetch('https://us-central1-nodemailer-341003.cloudfunctions.net/bambisKitchen/orders',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: orderid,
                type:'order',
                email,
                phone,
                name,
                address: fullAddress,
                delivery: type,
                comment: details,
                amount: charge,
                token: paymentMethod,
                date: date() + ', ' + new Date().getHours() + ':' + new Date().getMinutes(),
            })
            }).then(res => res.json())
            .then(r=>{
                setLoading(false);
                if(r.message === "Success"){

                    var orderlist = JSON.parse(localStorage.getItem('orderlist'));
                    orderlist.push({id:orderid, type:'order', email, phone, name, address: fullAddress, delivery: type, comment: details, amount: charge, date: date() + ', ' + new Date().getHours() + ':' + new Date().getMinutes()});
                    localStorage.setItem('orderlist', JSON.stringify(orderlist));

                    setIsActive(true);
                    completeDetails({details, orderid})
                }else{
                    setCardError('Card declined, please try again');
                }
            })
            .catch(err =>{ setCardError('An error occured, please try again'); setLoading(false)})
        }
    }

    var input = [
        {label:"Full name", value: name, textType:'name',  set:setName, required:true},
        {label:"Email", value: email, textType:'email', set:setEmail, required:true},
        {label:"Phone number", value: phone, textType:'phone', set:setPhone, required:true},
        {label:"Address", value: address, textType:'address', set:setAddress, required:true},
        {label:"Apt", value: apt, textType:'apt', set:setApt, required:false},
        {label:"City", value: city, textType:'city', set:setCity, required:true},
        {label:"State", value: stte, textType:'state', set:setStte, required:true},
        {label:"Zip code", value: zip, textType:'zip', set:setZip, required:true},
    ];


    if(cartItems.length === 0){
        return <Redirect to='/order' />
    }

    return (
      <>
      <Header>
            <Sub>
                <Link to='/' style={{textDecoration:'none'}}><Text pad='15px'size='18px'><img height={28} src={logo} alt="='logo"/>AMBI'S KITCHEN</Text></Link>
            </Sub>
      </Header>
        <div style={{display:'flex', marginTop:'10vh'}}>
            <OrderPage>
                <OrderContainer>
                    <div style={{padding:'0px 30px ', textAlign:'center', }}>
                         <Text size='25px' weight='600' color="black">Confirm Your Order & Pay</Text>
                    </div>
                    <TextContainer>
                        <Text size={'25px'} color="black" font={"MV Boli"}>BAMBI'S KITCHEN</Text>
                        <Text>Stoughton Boston, Massachusetts</Text>
                        <Text>Open Hours: We accept order anytime</Text>

                        <div style={{padding:"20px 0px", display:'flex', justifyContent:'space-between', flexWrap:"wrap"}}>
                            <Box>
                                <InnerBox>
                                <Text pad='0px 15px' lineHeight='1.5' size='13px' color="white" >Accepting orders for weekend pickup and delivery</Text>
                                </InnerBox>
                            </Box>
                            <Box>
                                <SubmitButton onClick={()=>type!=="delivery"?setType("delivery"):null} textColor={type==="delivery"?"white":null} color={type==="delivery"?"black":null}>Delivery</SubmitButton>
                                <SubmitButton onClick={()=>type==="delivery"?setType("pickup"):null} textColor={type==="pickup"?"white":null} color={type==="pickup"?"black":null}>Pickup</SubmitButton>
                            </Box>
                        </div>
                        <InfoBox>
                            <div style={{padding:"10px 15px"}}>
                                <img style={{position:'absolute'}} src={notics}/>
                                <Text size='17px' align="center" pad='20px 0px 10px 0px' color="white" weight='600'>{`You have selected a ${type==='delivery'? "Delivery":"Pickup"} order`} (Weekend pickup and delivery)</Text>
                                <Text align="center" pad='10px  0px' color="#909090">We will contact you about your order, or call us at (857)-238-2678</Text>
                            </div>
                        </InfoBox>
                    </TextContainer>
                </OrderContainer>

                <div style={{padding:'0px 20px'}}>
                        <div style={{    maxWidth:"900px", margin:"0 auto"}}>
                            <div style={{padding:'30px 0px 0px 0px'}}>
                                <Text  size="22px" color="black">Order summary</Text>
                                {error.type === 'payment' && <Text color="red" size="15px">{error.message}</Text>}
                            </div>

                            {cartItems.length !==0 &&

                                cartItems.map((cartI, i)=>{
                                    return data.menu.map(items => {
                                        return items.items.filter(item => item.id === cartI.id).map(item => {

                                            return(
                                                <Cont  key={i}>
                                                {cartI.quantity>1 && <Quant>x{cartI.quantity}</Quant>}
                                                    <CartItem>
                                                        <img src={item.img}/>
                                                        <div style={{padding:'0px 20px'}}>
                                                            <Text weight="500">{item.name}</Text>
                                                        </div>
                                                    </CartItem>
                                                    <Text color="#626262" weight="600">$ {(item.price).toFixed(2)}</Text>
                                                </Cont>
                                            )
                                        })
                                    })
                                })
                            }

                            <div style={{textAlign:'right'}}>
                                <Link to='/order'>
                                    <Text weight='600' size="14px" color="#B82F00">Edit</Text>
                                </Link>
                            </div>


                            <div style={{padding:'30px 0px 0px 0px'}}>
                                <Text weight='400' size="22px" color="black">Payment details</Text>
                            </div>

                            <div style={{padding:'10px 0px', display:'flex', flexWrap:'wrap', justifyContent:'space-between'}}>
                                    {input.map((item, index) => {
                                        return(
                                            <div key={index} style={{padding:'5px 0px', 
                                            width:`${item.textType==='address'? '70%':item.textType==='apt'? '29%': item.textType==='city' || item.textType==='state' || item.textType==='zip'?'32.5%':'100%'}`}}>
                                                <TextInput value={item.value} backgroundColor={'white'}
                                                label={item.label} required={item.required} 
                                                type='text' onChange={item.set} 
                                                error={error.type===item.textType? 'red':null} 
                                                errorMessage={error.type===item.textType? error.message:null}

                                                />
                                            </div>
                                        )
                                    })}
                            </div>
                           
                            
                            <form>  

                                <div style={{padding:'25px 10px',margin:'10px 0px', boxShadow:'1px 3px 6px #EAEAEA', borderRadius:'1px'}}>
                                    {cardError && <Text pad="0px 0px 20px 10px" size="12px" color="red">{cardError}</Text>}
                                    <CardElement/>
                                </div>

                                <div style={{display:'flex', justifyContent:'space-between', padding:"20px 0px 0px 0px"}}>
                                    <Text color="#7E7E7E">Subtotal</Text>
                                    <Text weight='600' size="15px" color="black">$ {(total).toFixed(2)}</Text>
                                </div> 

                                {type === 'delivery' && <div style={{display:'flex', justifyContent:'space-between'}}>
                                    <Text color="#7E7E7E">Delivery</Text>
                                    <Text weight='600' color="black">+ $5</Text>
                                </div>}

                                <div style={{display:'flex', justifyContent:'space-between'}}>
                                    <Text color="#7E7E7E">Tax</Text>
                                    <Text weight='600' size="15px" color="black"> $ {(total*0.0625).toFixed(2)}</Text>
                                </div>


                                <div style={{display:'flex', justifyContent:'space-between', padding:"20px 0px 0px 0px"}}>
                                    <Text color="black">Total</Text>
                                    <Text weight='600' size="23px" color="black">$ {charge.toFixed(2)}</Text>
                                </div> 

                                <div style={{height:'55px', width:'100%'}}>
                                    <SubmitButton disabled={loading? '#D86A44':false} onClick={(e)=>handleCard(e)} textColor="white" color="#B82F00">
                                    {!loading &&'Submit Payment'}
                                    {loading && <Rings heigth="50" width="50" color='#FFD9CB' ariaLabel='loading'/>}
                                    </SubmitButton>
                                </div>
                            </form>
                        </div>
                </div>

                <Footer>
                    <Text size="13px">
                    © 2022 BAMBI'S KITCHEN Terms of Service Privacy Policy
                    </Text>
                </Footer>
            </OrderPage>
        </div>
    </>
  );
}

const Header = styled.div`
    height:10vh;
    position:fixed;
    background-color:white;
    width:100%;
    margin-top:-10vh;
    z-index:37;
    min-height:60px;
    border: 1px solid #DADADA;
`;

const Sub = styled.div`
    height:10vh;
    max-width:1650px;
    margin:auto;
    display:flex;
    justify-content:space-between;
    align-items:center;
    & > p{
        @media (max-width:450px){
            font-size:15px;
        }
    }
`;


const OrderPage = styled.div`
    width: 100%;
    margin: 0;

`;
const OrderContainer = styled.div`
    padding: 30px 20px;
`;

const TextContainer = styled.div`
    max-width: 1100px;
    height:auto;
    margin: 0 auto;
    padding: 30px 0;
`;

const Box = styled.div`
    border: 1px solid #DADADA;
    border-radius: 8px;
    height: 70px;
    width: 300px;
    display: flex;
    margin: 20px 0px;
`;

const InfoBox = styled.div`
    border: 1px solid #DADADA;
    border-radius: 8px;
    height: auto;
    width: 100%;
    background-color: black;
    min-height: 100px;
`;


const Footer = styled.div`
    width: 100%;
    height: 90px;
     text-align: center;
     align-items: center;
     justify-content: center;  
     display: flex; 
`;

const InnerBox = styled.div`
    margin:5px;
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    text-align: center;
    background-color: #B82F00;
`;

const Cont = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
    ${'' /* background-color: #F9F9F9; */}
    border-bottom: 1px solid #E3E3E3;
    padding: 10px;
    height:60px;
    margin:8px 0px;
`;

const CartItem = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    height:100%;
    
    img{
        border-radius: 5px;
        height:100%;
        width:70px;
        object-fit: cover;
    }
`;


const Quant = styled.div`
    position: absolute;
    background-color: black;
    color: white;
    height:20px;
    width:20px;
    border-radius: 50%;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    margin-left: 65px;
    font-weight: bold;
    ${'' /* margin-left: -20px; */}
`;

const Form = styled.form`   
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Text, SubmitButton, TextInput } from "../components/input";
import { Link, useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import data from "../provider/pagedata";
import logo from "../images/logo.png";
import facebook from "../images/facebook.svg";
import instagram from "../images/instagram.svg";
import twitter from "../images/twitter.svg";
const slide = require("../images/slide.JPG");
const slide1 = require("../images/slide1.JPG");
const slide2 = require("../images/slide2.JPG");
const slide3 = require("../images/slide3.jpg");
const slide4 = require("../images/slide4.jpg");
const slide5 = require("../images/slide5.jpg");
const slide6 = require("../images/slide6.jpg");

const slides = [
  { id: 0, url: slide },
  { id: 1, url: slide1 },
  { id: 2, url: slide2 },
  { id: 3, url: slide3 },
  { id: 4, url: slide4 },
  { id: 5, url: slide5 },
  { id: 6, url: slide6 },
];

export default function Home(props) {
  const { pageData } = props;
  const {
    mainTopic, acceptingOrders, subTopic
  } = pageData;
  const history = useHistory();
  const [index, set] = useState(0);

  useEffect(() => {
    void setInterval(() => set((state) => (state + 1) % slides.length), 4000);
  }, []);

  const linkTo = [
    { name: "Catering", path: "/catering", isLink: true },
    { name: "Contact us", path: "tel:+16176971721", isLink: false },
  ];

  const social = [
    { name: "Facebook", url: "", img: facebook },
    {
      name: "Instagram",
      url: "https://www.instagram.com/thebambiskitchen/",
      img: instagram,
    },
    { name: "Twitter", url: "", img: twitter },
  ];

  return (
    <>
      <Header>
        <Sub>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Text pad="15px" weight="500" color="#B82F00" size="18px">
              <img height={28} src={logo} alt="='logo" />
              AMBI'S KITCHEN
            </Text>
          </Link>

          <div style={{ display: "flex" }}>
            {linkTo.map((item, index) => {
              return (
                <LinkTo
                  key={index}
                  onClick={() =>
                    !item.isLink && (window.location.href = item.path)
                  }
                  to={item.isLink === true ? item.path : ""}
                >
                  <Text color="#B82F00" pad="14px" size="15px">
                    {item.name}
                  </Text>
                </LinkTo>
              );
            })}
          </div>
          <LinkBtn to="/order">
            <Text color="#B82F00" weight="500" pad="0px 40px" size="14px">
              Order
            </Text>
          </LinkBtn>
        </Sub>
      </Header>
      <Hero style={{ backgroundImage: `url(${slides[index].url})` }}>
        <Back>
          <div style={{ width: "100%" }}>
            <HeroDetail>
              <Tag>
                <Text lineHeight="1.2" color="#FFD9CB" weight="500" size="13px">
                  Order
                </Text>
                <Text lineHeight="1.2" color="#FFD9CB" weight="500" size="13px">
                  online
                </Text>
              </Tag>
              <Text
                className="head"
                color="#FFD9CB"
                weight="600"
                pad="10px 0px"
                lineHeight="1"
                font="MV Boli"
                size="55px"
              >
               {mainTopic}
              </Text>
              <Text
                color="#FFD9CB"
                weight="500"
                pad="10px 0px 30px 0px"
                size="16px"
              >
                {subTopic} {acceptingOrders}
              </Text>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                }}
              >
                <Btn>
                  <SubmitButton
                    color="#B82F00"
                    textColor="#FFCAB7"
                    onClick={() => history.push("/order")}
                  >
                    View menu
                  </SubmitButton>
                </Btn>

                <Btn>
                  <SubmitButton
                    border="1px solid #FFD9CB"
                    color="transparent"
                    textColor="#FFD9CB"
                    onClick={() => history.push("/catering")}
                  >
                    Catering
                  </SubmitButton>
                </Btn>
              </div>
            </HeroDetail>
          </div>
        </Back>
      </Hero>

      <Footer>
        <SubFooter>
          <Text color="#FFD9CB" pad="10px 0px" size="13px">
            © 2022 BAMBI'S KITCHEN Terms of Service Privacy Policy
          </Text>

          <Text color="#FFD9CB" pad="10px 0px" size="13px">
            Stoughton Boston, Massachusetts
          </Text>

          <div>
            {social.map((item, index) => {
              return (
                <a
                  key={index}
                  target="_blank"
                  style={{ margin: "5px", padding: "5px" }}
                  href={item.url}
                >
                  <img height={20} key={index} src={item.img} alt={item.name} />
                </a>
              );
            })}
          </div>
        </SubFooter>
      </Footer>
    </>
  );
}

const Header = styled.div`
  height: 10vh;
  background: white;
  position: absolute;
  width: 100%;
  z-index: 37;
  min-height: 60px;
  border-bottom: 1px solid #fff;
`;

const Sub = styled.div`
  height: 100%;
  max-width: 1500px;
  padding: 0px 10px;
  background: transparent;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > p {
    @media (max-width: 450px) {
      font-size: 15px;
    }
  }
`;

const LinkBtn = styled(Link)`
  text-decoration: none;
  color: white;
  background-color: none;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.2px solid #b82f00;
  border-radius: 5px;
`;

const LinkTo = styled(Link)`
  text-decoration: none;
  margin-right: 10px;
  @media (max-width: 500px) {
    display: none;
  }
  & > p {
    transition: 0.3s ease-in-out;
  }

  &:hover {
    & > p {
      color: black;
      transition: 0.5s ease-in-out;
    }
  }
`;

const Hero = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 97vh;
  width: 100%;
  position: relative;
  z-index: 1;
`;

const HeroDetail = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  max-width: 700px;
  margin: auto;
  text-align: center;
  min-height: 600px;

  @media (max-width: 500px) {
    & > .head {
      font-size: 45px;
    }
  }
`;

const Back = styled.div`
  padding: 100px 0px 100px 0px;
  z-index: 10;
  width: 100%;
  min-height: 80vh;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
`;

const Btn = styled.div`
  height: 55px;
  width: 200px;
  margin: 10px 20px;
`;

const Tag = styled.div`
  position: absolute;
  top: 0px;
  right: 15px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #b82f00;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: rotate(25deg);

  @media (max-width: 900px) {
    zoom: 0.8;
    -moz-transform: scale(0.8);
    transition: all 0.5s ease-in-out;

    &:hover {
      -moz-transform: scale(1);
      -moz-transition: all 0.5s ease-in-out;
      -webkit-transform: scale(1);
      -webkit-transition: all 0.5s ease-in-out;
      transform: scale(1);
      transition: all 0.5s ease-in-out;
    }
  }
`;

const Footer = styled.div`
  min-height: 80px;
  background-color: #b82f00;
  width: 100%;
`;

const SubFooter = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  max-width: 1200px;
  margin: auto;
  flex-wrap: wrap;
  padding: 20px;
`;

import React,{useState, useEffect, useLayoutEffect} from "react";
import styled from "styled-components";
import food from '../images/food.jpg';
import notics from '../images/notics.svg';
import { Text, SubmitButton, TextInput, Selectable } from "../components/input";
import { Link, useHistory } from "react-router-dom";
import { motion } from "framer-motion"
import data from "../provider/pagedata";
import logo from "../images/logo.png";
import cart from "../images/cart.svg";
import del from "../images/delete.svg";
import cancel from "../images/cancel.svg";
import plus from "../images/plus.svg";
import minus from "../images/minus.svg";
import logo9 from "../images/logo9.png";


function truncate(str, n){
    return (str.length > n) ? str.substr(0, n-1) + '...' : str;
};


export default function Order() {
    const history = useHistory();
    const [type, setType] = useState('delivery');
    const [show , setShow] = useState(false);
    const [cartItems, setCartItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [quantity , setQuantity] = useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const [foodItems, setFoodItems] = useState({id:'', name:'', price:'', img:'', desc:'', options:null });
    const [instructions, setInstructions] = useState('');
    const [optionSelected, setOptionSelected] = useState(null);


    useLayoutEffect(() => {
        let s = setInterval(() => {
            handleTotal(0);
        }, 500);
        return () => clearInterval(s);
      }, [total, cartItems]);

    // localStorage
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
          } else {
            document.body.style.overflow = 'unset';
          }
        
        setInitialState();
        handleTotal(0);
        
        //listen for changes in localStorage
        window.addEventListener('storage', handleStorageChange);
            return () => {
            window.removeEventListener('storage', handleStorageChange);
        }
    }, [quantity, show, foodItems, isOpen])


    // set initial state of localStorage 
    const setInitialState = () => {
        const cart = localStorage.getItem('cart');
        if (cart) {
            setCartItems(JSON.parse(cart));
        }
    }

    // handle total price
    const handleTotal = (rr) => {
        setTotal(rr);
        if(cartItems.length > 0){
            cartItems.map(food => {
                data.menu.forEach(item => {
                    item.items.filter(item => item.id === food.id).map(item => {
                       return  setTotal(prev=>prev+=(item.price * food.quantity));
                    })
                })
            })
        }
    }
    // localStorage
    function handleStorageChange(event) {
        if (event.key === 'cart') {
            setCartItems(JSON.parse(event.newValue));
            handleTotal(0);
        }
    }



    // localStorage Remove
    function handleRemove(id) {
        const cart = JSON.parse(localStorage.getItem('cart'));
        const newCart = cart.filter(item => item.id !== id);
        localStorage.setItem('cart', JSON.stringify(newCart));
        setCartItems(newCart);
        handleTotal(0);
    }



    // localStorage add to cart
    function handleAdd(event, id) {
        event.preventDefault();
        const isCart= localStorage.getItem('cart')
        if (isCart !== null && isCart !== undefined && isCart !== '') {

            var cart = JSON.parse(localStorage.getItem('cart'));

            const objIndex = cart.findIndex(obj => obj.id === id.toString());

            if(objIndex !== -1) {
                cart[objIndex].quantity = cart[objIndex].quantity + quantity;
                cart[objIndex].instructions = cart[objIndex].instructions + instructions;
                cart[objIndex].options =  optionSelected !== '' ? optionSelected : cart[objIndex].options;
            }else{
                cart.push({id:foodItems.id, quantity, instructions, options:optionSelected ? optionSelected: ''});
            }
            
            localStorage.setItem('cart', JSON.stringify(cart));
            setCartItems(cart);
            setInstructions('');
            setIsOpen(false);
            setOptionSelected(null)
        }else{
            const newCart = [{id:foodItems.id, quantity: quantity, instructions, options:optionSelected ? optionSelected: ''}];
            localStorage.setItem('cart', JSON.stringify(newCart));
            setCartItems(newCart);
            setIsOpen(false);
            setInstructions('');
            setOptionSelected(null)
        }
    }




    const handleModal = (id) => {
        setOptionSelected(null)
        if(id){
            data.menu.forEach(item => {
                item.items.filter(item => item.id === id).map(item => {
                    setFoodItems(item);
                    setQuantity(1);
                   return setIsOpen(true);
                })
            })
        }
    }

    return (
      <>
      <Modal show={isOpen} >
        <ModalContent>
            <motion.div onClick={()=>{setIsOpen(false); setInstructions('')}} whileHover={{ scale: 1.05 }} 
            whileTap={{ scale: 0.98 }} style={{width:'12px', height:'12px', padding:'12px', borderRadius:'25px', 
            position:'absolute', backgroundColor:'white', margin:'25px', textAlign:'center', cursor:'pointer', boxShadow:'1px 1px 5px #000'}}>
                <img src={cancel} alt="cancel" style={{width:'100%', height:'100%'}}/>
            </motion.div>
            <img src={foodItems?.img} alt="logo" />
            <Text size='25px' weight='800' color="black" pad="30px 20px 10px 20px">{foodItems?.name}</Text>
            <Text size='13px' weight='400' color="black" pad="0px 20px 10px 20px">{foodItems?.desc}</Text>
            
            {foodItems?.options && optionSelected===null && <Text size='11px' weight='400' color="red" pad="0px 20px 10px 20px">Please select an option</Text>}

            {foodItems?.options && <div style={{padding:'0px 0px 10px 0px'}}>
                {foodItems?.options.map((list, i )=> {
                    return <div style={{height:'40px'}} key={i}>
                        <Selectable index={i} title={list.name} onClick={()=> setOptionSelected(i)} selected={optionSelected === i} />
                    </div> 
                })}
            </div>}

            <div style={{padding:'0px 20px'}}>
                <TextInput onChange={(e)=> setInstructions(e)} value={instructions}
                type="text" placeholder="Enter instructions" label='Enter instructions:' error={null} errorMessage={null}/>
            </div>
            
            <div style={{padding:'50px 20px 20px 20px', display:'flex', justifyContent:'space-between', alignItems:'center', textAlign:'center'}}>
                <Quantity>

                    <img src={minus} onClick={()=>setQuantity(quantity>1?quantity-1:1)} alt="cancel" style={{width:'10px',borderRadius:'20px',padding:'10px', height:'10px', marginRight:'10px', backgroundColor:'#D9D9D9'}}/>
                    <div style={{height:'12px', fontWeight:'600', padding:'10px 20px', borderRadius:'5px', backgroundColor:'#D9D9D9'}}>{quantity}</div>
                    <img src={plus} onClick={()=>setQuantity(quantity+1)} alt="cancel" style={{width:'10px', borderRadius:'20px', height:'10px',padding:'10px', margin:'0px 10px', backgroundColor:'#D9D9D9'}}/>

                </Quantity>
                <div style={{height:"45px", width:'200px', display:'flex'}}>
                    <SubmitButton disabled={foodItems?.options && optionSelected === null ? 'black': false} onClick={(e)=>handleAdd(e,foodItems.id )} textColor="white"
                     color={foodItems?.options && optionSelected === null ? null: "#B82F00"}>Add to cart {'$'+(quantity * foodItems.price).toFixed(2)}</SubmitButton>
                </div>
            </div>
        </ModalContent>
      </Modal>
      <Header>
          <Sub>
                <Link to='/' style={{textDecoration:'none'}}><Text pad='15px' size='18px'><img height={28} src={logo} alt="='logo"/>AMBI'S KITCHEN</Text></Link>


                <div style={{padding:"0px 15px"}}>
                    <Kart onClick={()=>show?setShow(false):setShow(true)}>
                        <img height={15} src={cart} alt="cart"/>
                      {cartItems.length!==0 &&  <Text weight='700' color='#B82F00' pad="0 0 0 20px">{cartItems.length}</Text>}
                    </Kart>
                </div>
          </Sub>
      </Header>
        <div style={{display:'flex', marginTop:'10vh'}}>
            <OrderPage>
                <OrderContainer>
                    <ImageContainer img={food}/>
                    <TextContainer>
                        {/* <Text size={'25px'} color="black" font={"MV Boli"}>BAMBI'S KITCHEN</Text> */}
                        <img src={logo9} alt="logo" height={100}/>
                        <Text>Stoughton Boston, Massachusetts</Text>
                        <Text>Open Hours: We accept order anytime</Text>

                        <div style={{padding:"20px 0px", display:'flex', justifyContent:'space-between', flexWrap:"wrap"}}>
                            <Box>
                                <InnerBox>
                                    <Text pad='0px 15px' lineHeight='1.5' size='13px' color="white" >Accepting orders this weekend</Text>
                                </InnerBox>
                            </Box>
                            <Box>
                                <SubmitButton onClick={()=>type!=="delivery"?setType("delivery"):null} textColor={type==="delivery"?"white":null} color={type==="delivery"?"black":null}>Delivery</SubmitButton>
                                <SubmitButton onClick={()=>type==="delivery"?setType("pickup"):null} textColor={type==="pickup"?"white":null} color={type==="pickup"?"black":null}>Pickup</SubmitButton>
                            </Box>
                        </div>
                        <InfoBox>
                            <div style={{padding:"10px 15px"}}>
                                <img style={{position:'absolute'}} src={notics}/>
                                <Text size='17px' align="center" pad='20px 0px 10px 0px' color="white" weight='600'>{`You have selected a ${type==='delivery'? "Delivery":"Pickup"} order`} (Weekend pickup and delivery)</Text>
                                <Text align="center" pad='10px  0px' color="#909090">We will contact you about your order, or call us at (857)-238-2678</Text>
                            </div>
                        </InfoBox>
                    </TextContainer>
                </OrderContainer>

                <MenuPage>

                    {data.menu.map((section, index)=>{
                        return(
                            <div key={index}>
                                <MenuSection>
                                    <Text size={'20px'} color="black">{section.section}</Text>
                                    <Text>{section.subText}</Text>

                                    <Item>
                                        {section.items.map((item, i)=>{
                                        return (
                                            <MenuItem onClick={()=>{handleModal(item.id)}} key={i} whileHover={{ scale: 1.03 }} 
                                                whileTap={{ scale: 0.98 }}>
                                                <div style={{padding:'10px 15px'}}>
                                                    <Text weight='600' color="black">{item.name}</Text>
                                                    <Text lineHeight='1.7' maxWidth='350px' size="12px">{truncate(item.desc, 90)}</Text>
                                                    <Text weight='700' color="black">$ {(item.price).toFixed(2)}</Text>
                                                </div>

                                                <MenuImg src={item.img}/>
                                            </MenuItem>)
                                        })}
                                    </Item>
                                </MenuSection>
                            </div>
                        )
                    })}
                
                </MenuPage>
                <Footer>
                    <Text size="13px">
                    © 2022 BAMBI'S KITCHEN Terms of Service Privacy Policy
                    </Text>
                </Footer>
            </OrderPage>
            <CartContainer show={show}>
                    <div style={{padding:'15px'}}>
                        <div style={{display:'flex', justifyContent:'space-between'}}>
                            <Text weight='600' size="17px" color="black">Your Cart</Text>
                            <Text weight='800' size="17px" color="black">$ {(total).toFixed(2)}</Text>
                        </div>

                        {cartItems.length !==0 &&

                            cartItems.map((cartI, i)=>{
                                return data.menu.map(items => {
                                    return items.items.filter(item => item.id === cartI.id).map(item => {

                                        return(
                                            <Cont  key={i}>
                                            {cartI.quantity>1 && <Quant>x{cartI.quantity}</Quant>}
                                                <CartItem>
                                                    <img src={item.img}/>
                                                    <div style={{padding:'0px 20px'}}>
                                                        <Text weight="500">{item.name}</Text>
                                                        <Text color="black" weight="600">$ {(item.price).toFixed(2)}</Text>
                                                    </div>
                                                </CartItem>
                                                <div onClick={()=>handleRemove(cartI.id)} style={{height:'15px', width:'15px', background:'white', borderRadius:'25px',padding:'10px', textAlign:'center', cursor:'pointer'}}>
                                                    <img height={15} src={del}/>
                                                </div>
                                            </Cont>
                                        )
                                    })
                                })
                            })
                        } 

                        {cartItems.length !==0 && <div style={{height:'55px', width:'100%'}}>
                            <SubmitButton onClick={()=> history.push('/checkout')} textColor="white" color="#B82F00">Checkout</SubmitButton>
                        </div>}

                    </div>
                    
            </CartContainer>
        </div>
    </>
  );
}

const Header = styled.div`
    height:10vh;
    position:fixed;
    background-color:white;
    width:100%;
    margin-top:-10vh;
    z-index:37;
    min-height:60px;
    border: 1px solid #DADADA;
    ${'' /* box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15); */}
`;

const Sub = styled.div`
    height:100%;
    max-width:1650px;
    padding:0px 10px;
    margin:auto;
    display:flex;
    justify-content:space-between;
    align-items:center;
    & > p{
        @media (max-width:450px){
            font-size:15px;
        }
    }
`;


const OrderPage = styled.div`
    width: 100%;
    margin: 0;
    margin-right: 350px;
    @media (max-width: 1150px){
        margin-right: 0px;
    }

`;
const OrderContainer = styled.div`
    padding: 30px 20px;
`;

const MenuPage = styled.div`
    width: 100%;
    height: auto;
    background-color: #f5f5f5;
`;




const CartContainer = styled.div`
    min-width: 350px;
    height:90vh;
    min-height:300px;
    border: 1px solid #DADADA;
    position: fixed;
    right: 0;
    display:block;
    background-color: white;
    z-index: 35;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
    }
    
    @media (max-width: 1150px){
        display: ${props=>props.show?'block':'none'};
    }
    @media (max-width: 750px){
        min-width: 0px;
        width: 100%;
    }
`;


const ImageContainer = styled.div` 
    max-width: 1016px;
    height:250px;
    border-radius: 8px;
    background-image: url(${props => props.img});
    background-size: cover;
    background-position: center;
    margin: 0 auto;
`;

const TextContainer = styled.div`
    max-width: 1016px;
    height:auto;
    margin: 0 auto;
    padding: 30px 0;
`;

const Box = styled.div`
    border: 1px solid #DADADA;
    border-radius: 8px;
    height: 70px;
    width: 300px;
    display: flex;
    margin: 20px 0px;
`;

const InfoBox = styled.div`
    border: 1px solid #DADADA;
    border-radius: 8px;
    height: auto;
    width: 100%;
    background-color: black;
    min-height: 100px;
`;

const MenuSection = styled.div`
    padding: 30px 20px;
    max-width: 1016px;
    height:auto;
    margin: 0 auto;

    @media (max-width: 550px){
        padding: 20px 10px;
    }

`;

const MenuItem =styled(motion.div)`
    display: flex;
    background-color: white;
    justify-content: space-between;
    border: 1px solid #DADADA;
    border-radius: 5px;
    min-height: 125px;
    margin: 5px;
    cursor: pointer;
    min-width: 350px;
    flex:  5rem;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;

    @media (max-width: 790px){
        min-width: auto;
        flex:  auto;
        max-width:100%;
    }

`;


const MenuImg = styled.div`
    width: 150px;
    height:100%;
    background-image: url(${props => props.src});
    background-size: cover;
    background-position: center;
    border-radius: 0px 4px  4px 0px;

    @media (max-width: 400px){
        width: 170px;
    }
`;

const Footer = styled.div`
    width: 100%;
    height: 90px;
     text-align: center;
     align-items: center;
     justify-content: center;  
     display: flex; 
`;

const Item = styled.div`
    display:flex; 
    flex-wrap: wrap;
`
const Kart = styled.div`
    display:flex;
    height:38px;
    padding:0px 20px;
    justify-content: space-between;
    align-items: center;
    background-color: #FFF1EC;
    border-radius: 40px;
    pointer-events: none;
    @media (max-width: 1150px){
        cursor: pointer;
        pointer-events:initial;
    }
`;

const InnerBox = styled.div`
    margin:5px;
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    text-align: center;
    background-color: #B82F00;
`;

const Cont = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F9F9F9;
    border-radius: 5px;
    padding: 10px;
    height:60px;
    margin:8px 0px;
`;

const CartItem = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    height:100%;
    
    img{
        border-radius: 5px;
        height:100%;
        width:70px;
        object-fit: cover;
    }
`;

const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 100;
    display: ${props=>props.show?'block':'none'};
    body{
        overflow-y: ${props=>props.show?'hidden':'auto'};
    }
`;

const ModalContent = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 550px;
    height: auto;
    background-color: white;
    border-radius: 10px;
    z-index: 101;
    min-height: 300px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
    overflow-y: scroll;
    img{
        width:100%;
        height:250px;
        object-fit: cover;
        border-radius: 10px 10px 0px 0px;
        @media (max-width: 600px){
        border-radius: 0px;
        }
    }
    @media (max-width: 600px){
        width: 100%;
        height:100%;
        border-radius: 0px;

    }
`;

const Quantity = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
    height:100%;

`;

const Quant = styled.div`
    position: absolute;
    background-color: black;
    color: white;
    height:20px;
    width:20px;
    border-radius: 50%;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: bold;
    left: 85px;
    ${'' /* margin-left: -20px; */}
`;
import React,{useState, useEffect, useRef} from "react";
import styled from "styled-components";
import food from '../images/food.jpg';
import { Text, SubmitButton, TextInput } from "../components/input";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";


export default function Catering() {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [location, setLocation] = useState('');
    const [date, setDate] = useState('');
    const [size, setSize] = useState('');
    const [comment, setComment] = useState('');
    const [error, setError] = useState({type:null, message:null})
    const [success, setSuccess] = useState('')

    // localStorage
    useEffect(() => {

    }, [])

    const submitForm = async (e) => {
        e.preventDefault();
        setError({type:null, message:null});
        var orderid = Math.floor(Math.random() * 1000000000000)

        let re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(name===''|| name === null){
            return setError({type:'name', message:'Full name is required'})
        }

        if (phone === '' || phone === null) {
            return setError({type:'email', message:'Please enter a valid phone number'});
        }

        if (location === '' || location === null) {
            return setError({type:'email', message:'Please enter a valid location'});
        }

        if (date === '' || date === null) {
            return setError({type:'email', message:'Please enter a valid date'});
        }
        
        fetch('https://us-central1-nodemailer-341003.cloudfunctions.net/bambisKitchen/orders',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: orderid,
                type:'catering',
                phone,
                name,
                email: 'ibironke_sanni@yahoo.com',
                address: location,
                delivery: 'catering',
                comment: comment,
                amount: 'null',
                token: 'null',
                date: date,
                size: size,
            })
            }).then(res => res.json())
            .then(r=>{
                console.log(r)
                if(r.message === "Success"){
                    setSuccess('Your order has been placed successfully, We will reach out to you shortly');
                    setName('');
                    setPhone('');
                    setLocation('');
                    setDate('');
                    setSize('');
                    setComment('');

                }
            })
            .catch(console.log)
    }

    var inputCater = [
        {label:"Full name", value: name, textType:'name',  set:setName, required:true},
        {label:"Phone number", value: phone, textType:'phone', set:setPhone, required:true},
        {label:"Location", value: location, textType:'location', set:setLocation, required:true},
        {label:"Date", value: date, textType:'date', set:setDate, required:true},
        {label:"Size of party", value: size, textType:'size', set:setSize, required:false},   
        {label:"Comment", value: comment, textType:'comment', set:setComment, required:false},
    ];
    

    return (
      <>
      <Header>
            <Sub>
                <Link to='/' style={{textDecoration:'none'}}><Text pad='15px' size='18px'><img height={28} src={logo} alt="='logo"/>AMBI'S KITCHEN</Text></Link>

                <LinkA to='/order'>
                    <Text color="#fff" pad='15px' size='14px'>Order now</Text>
                </LinkA>
            </Sub>
      </Header>
        <div style={{display:'block', marginTop:'10vh'}}>
            <OrderPage>
                <ImageContainer img={food}>
                    <Text shadow='2px 2px 10px #000000' font='Raleway' margin="-50px 0px 0px 0px"  size='30px' weight='800'  color='#fff'>Catering</Text>
                </ImageContainer>

                <Form>
                    <OrderContainer>
                        <Text size="20px" weight='600'>Want to cater?</Text>
                        <Text size="16px" weight='600'>Create your request and we will reach out to you ASAP</Text>

                        <Text color="green" pad="30px 0px 0px 0px" size="16px" weight='600'>{success}</Text>

                    </OrderContainer>
                    <div style={{padding:'30px'}}>
                        {inputCater.map((item, index) => {
                            return(
                                <div key={index} style={{padding:'5px 0px'}}>
                                    <TextInput value={item.value} 
                                    label={item.label} required={item.required} 
                                    type='text' onChange={item.set} 
                                    error={error.type===item.textType? 'red':null} 
                                    errorMessage={error.type===item.textType? error.message:null}

                                    />
                                </div>
                            )
                        })}
                        
                    </div>

                    <div style={{height:"55px", padding:'0px 30px 20px 30px'}}>
                        <SubmitButton onClick={(e)=>submitForm(e)} textColor="white" color="#B82F00">Submit</SubmitButton>
                    </div>

                </Form>

                
                
            </OrderPage>
            <Footer>
                    <Text size="13px">
                    © 2022 BAMBI'S KITCHEN Terms of Service Privacy Policy
                    </Text>
            </Footer>
        </div>
    </>
  );
}

const Header = styled.div`
    height:10vh;
    position:fixed;
    background-color:white;
    width:100%;
    margin-top:-10vh;
    z-index:37;
    min-height:60px;
    border: 1px solid #DADADA;
`;

const Sub = styled.div`
    height:10vh;
    max-width:1550px;
    margin:auto;
    display:flex;
    justify-content:space-between;
    align-items:center;
    & > p{
        @media (max-width:450px){
            font-size:15px;
        }
    }
`;


const OrderPage = styled.div`
    width: 100%;
    margin: 0;

    padding-bottom: 200px;

`;
const OrderContainer = styled.div`
    padding: 0px 30px;
`;


const ImageContainer = styled.div` 
    max-width: 1200px;
    height:250px;
    background-image: url(${props => props.img});
    background-size: cover;
    background-position: center;
    margin: 0 auto;
    display:flex;
    justify-content:center;
    align-items:center;
    
`;


const Footer = styled.div`
    width: 100%;
    height: 90px;
     text-align: center;
     align-items: center;
     justify-content: center;  
     display: flex; 
    bottom: 0;
     background-color: #F5F5F5;
`;

const Form = styled.div`
    max-width:600px;
    margin:-80px auto -80px auto;
    background-color:white;
    border-radius:10px;
    padding:20px 0px;
    box-shadow: 0px 4px 6px #CDCDCD;
    z-index:10;
`;


const LinkA = styled(Link)`
    text-decoration:none;
    margin:0px 20px;
    color:white;
    height:40px;
    border-radius:5px;
    padding:0px 10px;
    background-color:#B82F00;
    display:flex;
    justify-content:center;
    align-items:center;

`;